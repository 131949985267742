<template>
  <div v-if="isLoading" class="flex gap-5 items-center">
    <div
      class="border-gray-300 m-5 h-10 w-10 animate-spin rounded-full border-8 border-t-green-600"
    />
    Laden...
  </div>
  <div v-else>
    <div class="flex w-full justify-between items-center">
      <div>
        {{ numberOfLocations }} resultaten gevonden
        <span v-if="selectedAddresses.length">
          / {{ selectedAddresses.length }} geselecteeerd
        </span>
      </div>
      <div v-if="numberOfLocations" class="flex gap-2">
        <div>
          <button
            class="bg-blue-500 hover:bg-blue-400 text-md rounded px-2 py-1 text-white flex gap-1 items-center"
            @click="download.modal = true"
          >
            <DocumentTextIcon class="w-5 h-5" />
            Download documenten
          </button>
        </div>
        <div>
          <button
            class="bg-blue-500 hover:bg-blue-400 rounded px-2 py-1 text-white flex gap-1 items-center"
            @click="excel.modal = true"
          >
            <TableIcon class="w-5 h-5" />
            Exporteer gegevens
          </button>
        </div>
      </div>
    </div>
    <div class="flex gap-2 flex-cols items-center mb-2 justify-between">
      <table class="w-full border-separate border-spacing-y-3" v-if="locations">
        <thead>
          <tr class="leading-10 bg-gray-200 text-left">
            <th class="px-2 rounded-l-lg">
              <input
                type="checkbox"
                @change="selectAllAddresses"
                :checked="selectedAddresses.length && selectedAddresses.length === locations.length"
              />
            </th>

            <th class="px-2">VHE</th>
            <th class="px-2">Adres</th>
            <th class="px-2">Status</th>
          </tr>
        </thead>
        <tbody>
          <location-list-item
            v-for="location in locations"
            :key="location.id"
            :locations="locations.map((location) => location.location_id)"
            :location="location"
            :selectedAddresses="selectedAddresses"
            :lastSelectedLocation="lastSelectedLocation"
            @toggleSelection="toggleSelection"
            @toggle="toggleAddressSelection"
            class="hover:bg-gray-100"
          />
        </tbody>
      </table>
    </div>
    <notification-message :message="notification.message" :type="notification.type" />
    <div class="flex" v-show="hasNextPage">
      <div class="mx-auto mt-3 mb-5">
        <button
          @click="$emit('fetchNextPage')"
          class="bg-blue-400 rounded p-1 disabled:bg-gray-100"
        >
          Laad meer...
        </button>
      </div>
    </div>
    <GenericModal
      v-show="download.modal"
      @close="download.modal = false"
      @submit="downloadZip"
      submit-label="Download"
      :disabled="
        (!download.asbuilt && !download.salesContract && !download.design) ||
        !!download.progress ||
        numberOfSelectedLocationsOrAll > 5000 || (numberOfSelectedLocationsOrAll > 500 && download.design)
      "
    >
      <div v-if="numberOfSelectedLocationsOrAll > 5000" class="text-red-500">
        Je kunt van maximaal 5.000 adressen tegelijk documenten downloaden
      </div>
      <div v-else>
        Download documenten voor {{ numberOfSelectedLocationsOrAll }} adressen als ZIP
        <FormField type="checkbox" label="Asbuilt PDF" :value="true" v-model="download.asbuilt" />
        <FormField
          type="checkbox"
          label="Akkoordverklaring digitaal of scan"
          :value="true"
          v-model="download.salesContract"
        />
        <FormField
          type="checkbox"
          label="Legplan als PDF"
          :value="true"
          v-model="download.design"
        />
      </div>

      <div v-if="numberOfSelectedLocationsOrAll > 500 && download.design" class="text-red-500">
        Je kunt van maximaal 500 adressen tegelijk de legplan PDF downloaden
      </div>
      <div v-if="download.progress == 1">Documenten laden...</div>
      <div v-else-if="download.progress">{{ displayBytes(download.progress) }} gedownload...</div>
    </GenericModal>

    <GenericModal
      v-show="excel.modal"
      @close="excel.modal = false"
      @submit="$emit('downloadExport')"
      submit-label="Download"
      :disabled="numberOfLocations > 5000 || (numberOfLocations > 500 && excel.tenantDetails)"
    >
      <div v-if="numberOfLocations > 5000" class="text-red-500">
        Je kunt van maximaal 5.000 adressen tegelijk gegevens exporteren
      </div>
      <div v-else>
        Exporteer gegevens van {{ numberOfLocations }} adressen als Excel (XLSX).
        <FormField
          type="checkbox"
          label="Inclusief bewonersgegeven (naam, email, telefoon)"
          :value="true"
          v-model="excel.tenantDetails"
        />
        <div
          v-if="numberOfLocations > 500"
          class="text-sm"
          :class="{ 'text-red-400': excel.tenantDetails }"
        >
          Je kunt van maximaal 500 adressen bewonersgegevens exporteren
        </div>
        <div v-else-if="excel.tenantDetails" class="text-sm text-gray-400">
          De export bevat privacygevoelige informatie van bewoners. Verwerk het document met
          inachtneming van de Algemene Verordening Gegegevensbescherming (AVG).
        </div>
      </div>
    </GenericModal>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch } from 'vue'
import NotificationMessage from './NotificationMessage.vue'
import LocationListItem from './LocationListItem.vue'
import { useKeyModifier } from '@vueuse/core'
import { getCookie } from '@/util/fetch.js'
import GenericModal from '@/components/shared/GenericModal.vue'
import FormField from '@/components/shared/FormField.vue'
import { DocumentTextIcon, TableIcon } from '@vue-hero-icons/outline'
import { useNotificationsStore } from '@/store/notifications'

const emit = defineEmits(['fetchNextPage', 'downloadExport'])

const notifications = useNotificationsStore()

const download = reactive({
  modal: false,
  asbuilt: false,
  salesContract: false,
  design: false,
  progress: 0,
})

const excel = reactive({
  modal: false,
  tenantDetails: false,
})

function displayBytes(bytes) {
  const MB = bytes / 1024 / 1024
  const digits = MB < 10 ? 1 : 0
  return Intl.NumberFormat('nl-NL', {
    style: 'unit',
    unit: 'megabyte',
    maximumFractionDigits: digits,
  }).format(MB)
}

const props = defineProps({
  filter: {
    type: Object,
    required: true,
  },
  locations: {
    type: Array,
  },
  hasNextPage: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
  },
  numberOfLocations: {
    type: Number,
    default: 0,
  },
})

const numberOfSelectedLocationsOrAll = computed(
  () => selectedAddresses.value.length || props.numberOfLocations
)

const selectedAddresses = ref([])
const lastSelectedLocation = ref('')
const notification = ref({ message: '', type: '' })
const shiftState = useKeyModifier('Shift')

const selectAllAddresses = (event) => {
  if (event.target.checked) {
    selectedAddresses.value = props.locations.map((location) => location.location_id)
  } else {
    selectedAddresses.value = []
  }
}

const toggleAddressSelection = (addressId) => {
  if (selectedAddresses.value.includes(addressId)) {
    selectedAddresses.value = selectedAddresses.value.filter((id) => id !== addressId)
  } else {
    lastSelectedLocation.value = addressId
    selectedAddresses.value = [...selectedAddresses.value, addressId]
  }
}

watch(
  () => props.filter,
  () => {
    selectedAddresses.value = []
  },
  { deep: true }
)

async function downloadZip() {
  try {
    const types = []
    if (download.asbuilt) {
      types.push('asbuilt')
    }
    if (download.salesContract) {
      types.push('sales_contract')
    }
    if (download.design) {
      types.push('design')
    }

    const payload = { types }
    if (selectedAddresses.value.length) {
      payload.location_ids = selectedAddresses.value
    } else {
      payload.filter = {
        project_ids: props.filter.projects,
        groups_ds: props.filter.groups,
        status: props.filter.statuses,
        woco_names: props.filter.wocos,
        query: props.filter.query,
      }
    }

    download.progress = 1
    const result = await fetch(`/tickets/api/v1/location-downloads`, {
      method: 'POST',
      // Broekweg 95 2161XC - available asbuilt 2161XC95
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${getCookie('access_token')}`,
        'Content-Type': 'application/json',
      },
    })
    if (result) {
      const reader = result.body.getReader()
      const chunks = []
      let bytesDownloaded = 0
      while (true) {
        try {
          const { value, done } = await reader.read()
          if (done) {
            break
          }
          bytesDownloaded += value.length
          chunks.push(value)

          download.progress = bytesDownloaded
          console.log('download progress:', bytesDownloaded, ', unknown total')
        } catch (error) {
          console.error('error:', error)
          success = false
          break
        }
      }
      download.progress = 0

      const match = /filename="([^"]+)"/.exec(result.headers.get('content-disposition'))
      const filename = match[1]

      if (bytesDownloaded > 30) {
        const element = document.createElement('a')
        const blob = new Blob(chunks, {
          type: 'application/zip',
        })
        element.href = window.URL.createObjectURL(blob)
        element.setAttribute('download', filename)
        element.click()
      } else {
        notifications.add('Geen asbuilts/akkoordverklaringen gevonden voor deze adressen', 'error')
      }

      download.modal = false
    } else {
      notifications.add('Download kon niet worden gestart', 'error')
      console.error('Error downloading', await result.text())
    }
  } catch (error) {
    console.error(error)
    notifications.add('Download werkt niet', 'error')
  }
}

const toggleSelection = (id) => {
  const locationsIds = props.locations.map((location) => location.location_id)
  if (shiftState.value === true && lastSelectedLocation.value !== null) {
    const startIndex = locationsIds.indexOf(lastSelectedLocation.value)
    const endIndex = locationsIds.indexOf(id)
    let addressesToToggle = []

    if (startIndex < endIndex) {
      addressesToToggle = locationsIds.slice(startIndex + 1, endIndex + 1)
    } else {
      addressesToToggle = locationsIds.slice(endIndex, startIndex).reverse()
    }
    for (const address of addressesToToggle) {
      toggleAddressSelection(address)
    }
    return
  }
  toggleAddressSelection(id)
}
</script>
